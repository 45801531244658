/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .heroText {
    font-size: 4rem;
  }
  .heroSubtitle {
    font-size: 3rem;
  }
  .signupText {
    font-size: 6rem;
    top: -6rem;
    left: -2rem;
  }
  .signupContainer {
    height: 8rem;
  }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .heroText {
    font-size: 4rem;
  }
  .heroSubtitle {
    font-size: 3rem;
  }
  .signupText {
    top: -6rem;
    left: -1rem;
    font-size: 6rem;
  }
  .signupContainer {
    width: 100%;
    height: 8rem;
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .heroText {
    font-size: 6rem;
  }
  .heroSubtitle {
    font-size: 4rem;
  }
  .signupText {
    top: -6rem;
    left: -1rem;
    font-size: 8rem;
  }
  .signupContainer {
    width: 100%;
    height: 10rem;
  }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .heroText {
    font-size: 8rem;
  }
  .heroSubtitle {
    font-size: 6rem;
  }
  .signupText {
    top: -2rem;
    left: -1rem;
    font-size: 10rem;
  }
  .signupContainer {
    width: 100%;
    height: 16rem;
  }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .heroText {
    font-size: 8rem;
  }
  .heroSubtitle {
    font-size: 6rem;
  }
  .signupText {
    top: -2rem;
    left: -2rem;
    font-size: 10rem;
  }
  .signupContainer {
    width: 100%;
    height: 16rem;
  }
}

.slick-slide img {
  margin: auto;
  outline: none;
}

.slick-slide div {
  outline: none;
}

@keyframes inOutFocus {
  0% {
    filter: blur(5px);
  }

  50% {
    filter: blur(0px);
  }

  100% {
    filter: blur(5px);
  }
}

.blurText {
  animation: inOutFocus 3s infinite;
}
